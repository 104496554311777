<template>
    <div :id="ref">
        <label v-if="label">{{label}}</label>
        <ejs-richtexteditor
            :name="field"
            :ref="ref"
            :enabled="!disabled"
            :placeholder="placeholder"
            :height="height"
            :insertImageSettings="imageSettings"
            :toolbarSettings="toolbarSettings"
            :actionComplete="actionCompleteHandler"
            @change="updateContent">
        </ejs-richtexteditor>
    </div>
</template>

<script>
    import { EditorState } from "@codemirror/state";
    import {EditorView, keymap, lineNumbers} from "@codemirror/view";
    import { html } from "@codemirror/lang-html";
    import { autocompletion } from "@codemirror/autocomplete";
    import {indentWithTab} from "@codemirror/commands"
    import { expandAbbreviation } from '@emmetio/codemirror6-plugin';


    import Vue from "vue";
    import { RichTextEditorPlugin, Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar } from "@syncfusion/ej2-vue-richtexteditor";
    import {oneDark} from "@codemirror/theme-one-dark";
    Vue.use(RichTextEditorPlugin);

    export default {
        name: "RichText",
        props: {
            value: {
                required: false
            },
            field: {
                required: true,
                type: String
            },
            //https://ej2.syncfusion.com/vue/documentation/rich-text-editor/toolbar/
            toolbarSettings: {
                required: false,
                type: Object,
                default() {
                    return {
                        items: [ 'Bold', 'Italic', 'Underline', 'FontName', 'FontSize', 'FontColor', 'Formats', 'Alignments', 'CreateLink', 'Image', 'Undo', 'Redo', 'FullScreen', 'SourceCode']
                    }
                },
            },
            height: {
                required: false,
                type: Number,
            },
            label: {
                required: false
            },
            placeholder: {
                required: false,
                type: String,
                default() {
                    return 'Insérer le contenu ici.'
                }
            },
            disabled: {
                required: false,
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        provide: {
            richtexteditor:[Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar]
        },
        methods: {
            updateContent() {
                this.mutableValue = this.$refs[this.ref].ej2Instances.getHtml()
                this.$emit('change', this.mutableValue)
            },
            refreshUi() {
                if(this.$refs[this.ref]) {
                    this.$refs[this.ref].ej2Instances.refreshUI()
                }
            },
            /**
             * Il y a un bug sur le composant SyncFusion, si il est masqué (par exemple avec un onglet/tab Bootstrap),
             * la toolbar du composant ne s'affiche pas, il faut faire un refreshUi() pour forcer son affichage.
             * La solution est d'utiliser un IntersectionObserver pour savoir quand le composant est affiché
             */
            observe() {
                this.observedElement = document.getElementById(this.ref)
                this.observer = new IntersectionObserver(()=> this.refreshUi())
                this.observer.observe(this.observedElement)
            },
            mirrorConversion(e) {
                let textArea = this.$refs[this.ref].ej2Instances.contentModule.getEditPanel();
                let id = this.$refs[this.ref].ej2Instances.getID() +  'mirror-view';
                let mirrorView = this.$refs[this.ref].$el.parentNode.querySelector('#' + id);
                if (e.targetItem === 'Preview') {
                    textArea.style.display = 'block';
                    mirrorView.style.display = 'none';
                    textArea.innerHTML = this.myCodeMirror.state.doc.toString();
                    this.updateContent()
                } else {
                    if (!mirrorView) {
                        mirrorView = document.createElement('div', { className: 'e-content' });
                        mirrorView.id = id;
                        textArea.parentNode.appendChild(mirrorView);
                    } else {
                        mirrorView.innerHTML = '';
                    }
                    textArea.style.display = 'none';
                    mirrorView.style.display = 'block';
                    this.renderCodeMirror(mirrorView, this.mutableValue);
                }
            },
            renderCodeMirror(mirrorView, content) {
                this.myCodeMirror = new EditorView({
                    state: EditorState.create({
                        doc: content,
                        extensions: [
                            autocompletion(),
                            html(),
                            lineNumbers(),
                            oneDark,
                            keymap.of([indentWithTab]),
                            keymap.of([{
                                key: 'Mod-e',
                                run: expandAbbreviation,
                                preventDefault: true
                            }]),
                        ],
                    }),
                    parent: mirrorView,
                });
            },
            actionCompleteHandler: function(e) {
                if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
                    this.$refs[this.ref].ej2Instances.sourceCodeModule.getPanel().style.display = 'none';
                    this.mirrorConversion(e);
                }
                else {
                    setTimeout(()=> {
                        this.$refs[this.ref].ej2Instances.toolbarModule.refreshToolbarOverflow();
                    }, 400);
                }
            }
        },
        mounted() {
            this.mutableValue = this.value ? this.value : ''
            this.$refs[this.ref].ej2Instances.value = this.mutableValue
            this.observe()
        },
        beforeDestroy() {
            this.observer.unobserve(this.observedElement)
        },
        data() {
            return {
                ref: 'rich-editor-'+new Date().valueOf(),
                mutableValue: null,
                imageSettings :{
                    saveFormat: "Base64"
                },
                observer: null,
                observedElement: null,
                myCodeMirror: null,
                startState: null
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.mutableValue = JSON.parse(JSON.stringify(newVal))
                this.$refs[this.ref].ej2Instances.value = this.mutableValue
                this.refreshUi()
            }
        }
    }
</script>

<style>
.e-richtexteditor.e-rte-full-screen {
    z-index: 1000000000000;
    .e-rte-srctextarea {
        height: calc(100vh - 41px)!important;
    }
}
</style>
