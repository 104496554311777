<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    Patients
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Patient"
    }
</script>

<style scoped>

</style>