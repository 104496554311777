<script>
import doctorMessageApi from "@/api/doctorMessage";
import RichText from '../../../components/generic/RichText.vue'
export default {
    name: "Index",
    components: {
        RichText
    },
    data() {
        return {
            doctors: null,
            languages: null,
            form: {
                items: []
            },
            loading: false,
            initialLoading: true
        }
    },
    methods: {
        async init() {
            this.initialLoading = true
            try {
                let res = await doctorMessageApi.init()
                this.doctors = res.data.doctors
                this.languages = res.data.languages
                this.form.items = res.data.items
            }catch (e) {
                this.$toast.error('Une erreur s\'est produite')
            }
            this.initialLoading = false
        },
        handleChange(doctor, code, data) {
            let existing = this.form.items.findIndex(d=> d.doctor_id === doctor.id && d.code === code)
            if(existing !== -1) {
                this.form.items.splice(existing, 1)
            }
            let item = {
                doctor_id: doctor.id,
                code: code,
                message: data
            }
            this.form.items = [...this.form.items, item]
        },
        getImageLink(code) {
            return require('@/assets/flags/'+code+'.png')
        },
        async onSave() {
            this.loading = true
            try {
                let res = await doctorMessageApi.store(this.form)
                this.form.items = [...res.data.items]
                this.$toast.success('Messages enregistrés avec succès !')
            }catch (e) {
                this.$toast.error('Une erreur s\'est produite')
            }
            this.loading = false
        },
        onKKeyDownCtrl(e) {
            if(e.key === 's') {
                e.preventDefault()
                e.stopPropagation()
                this.onSave()
            }
        }
    },
    mounted() {
        this.init()
    },
}
</script>

<template>
    <div>
        <transition name="fade" mode="out-in">
            <div v-if="initialLoading === false" key="content" class="container-fluid mt-3" @keydown.ctrl="(e)=>onKKeyDownCtrl(e)">
                <div class="row  mt-3">
                    <transition name="fade">
                        <div v-if="doctors" class="col-md-12">
                            <ul class="nav nav-tabs">
                                <li v-for="(doctor, index) in doctors" class="nav-item">
                                    <a :id="'tab-link'+doctor.id" :ref="'tab-link'+doctor.id" class="nav-link" :class="index === 0 ? 'active' : ''" data-toggle="tab" :href="'#doctor-'+doctor.id">{{doctor.full_name}}</a>
                                </li>
                            </ul>
                            <div class="tab-content mt-4">
                                <div v-for="(doctor, index) in doctors" class="tab-pane fade" :class="index === 0 ? 'show active' : ''" :id="'doctor-'+doctor.id">
                                    <div class="row">
                                        <div class="col-md-12 d-flex justify-content-between mb-3">
                                            <h4>Message personnalisé de {{doctor.full_name}}</h4>
                                        </div>
                                    </div>
                                    <div v-for="lang in languages" class="row">
                                        <div class="col-md-12 mb-3">
                                            <img class="flag" :src="getImageLink(lang.code)" alt="">
                                            <rich-text field="" @change="data=>handleChange(doctor, lang.code, data)" :value="form.items.find(i=> i.doctor_id === doctor.id && i.code === lang.code)?.message"></rich-text>
                                            <hr class="dashed">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
                <div class="row mb-3">
                    <div class="col-md-12 text-right">
                        <button class="btn btn-success btn-sm" @click="onSave">
                            <span v-if="loading" class="spinner-border spinner-border-sm mr-2" aria-hidden="true"></span>
                            <i v-else class="fa fa-check mr-2"></i>
                            Enregistrer
                        </button>
                    </div>
                </div>
            </div>
            <div v-else key="skeleton">
                <div class="container-fluid mt-3">
                    <div class="col-md-12">
                        <PuSkeleton height="35px"  width="350px"></PuSkeleton>
                        <div class="py-2">
                            <PuSkeleton height="35px"  width="35px" circle></PuSkeleton>
                        </div>
                        <PuSkeleton height="350px" width="100%"></PuSkeleton>
                        <hr class="dashed">
                        <div class="py-2">
                            <PuSkeleton height="35px"  width="35px" circle></PuSkeleton>
                        </div>
                        <PuSkeleton height="350px" width="100%"></PuSkeleton>
                        <hr class="dashed">
                        <div class="py-2">
                            <PuSkeleton height="35px"  width="35px" circle></PuSkeleton>
                        </div>
                        <PuSkeleton height="350px" width="100%"></PuSkeleton>
                    </div>
                </div>
            </div>
        </transition>
    </div>

</template>

<style scoped>
.flag {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 1rem;
}
</style>