import axios from "axios";
import store from "@/store";

export default {
    init: ()=> {
        return axios.get('/v1/admin/doctor-message/init', {
            params: {
                app_id: Vue.$config.app_id
            },
            headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        });
    },
    store: (data)=> {
        console.log('ici')
        return axios.post('/v1/admin/doctor-message/store', {...data, app_id: Vue.$config.app_id},{
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }});
    }
}