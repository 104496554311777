<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 mt-3">
                    <div class="alert alert-info">
                        <div v-html="$t('traduction.info')"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <label for="search">{{$t('traduction.rechercher')}} :</label>
                    <input :disabled="!filteredCategories" id="search" type="text" class="form-control" @input="onSearch">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <hr>
                </div>
            </div>
            <transition name="fade" mode="out-in">
                <div v-if="filteredCategories" key="traduction">
                    <div class="row" v-for="(category, key) in filteredCategories" :key="'trad-row-'+key">
                        <div class="col-md-12">
                            <h4>{{key}}</h4>
                            <div class="row">
                                <div class="col-lg-4 col-md-6" v-for="(term, ke) in category">
                                    <div class="card">
                                        <div class="card-header">
                                            <h5>{{ke}}</h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="mb-2" v-for="(word, k) in term">
                                                <template v-if="word">
                                                    <div class="translation-wrapper">
                                                        <img class="flag" :src="src(k)" alt="">
                                                        <textarea :id="key + '-' + ke + '-' + k" class="form-control traduction-area" v-model="word.value"  @change="onSaveTranslation(word)">

                                                        </textarea>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="row">
                        <div v-for="i in 50" class="col-lg-4 col-md-6" :key="'skeleton-'+i">
                            <div class="card">
                                <div class="card-header">
                                    <PuSkeleton :width="Math.random() * (300 - 30) + 30+'px'" height="25px"></PuSkeleton>
                                </div>
                                <div class="card-body">
                                    <div v-for="j in 5" class="position-relative my-2">
                                        <PuSkeleton width="100%" height="50px">
                                        </PuSkeleton>
                                        <div class="position-absolute" style="right: 5px; top:50%; transform: translateY(-50%)">
                                            <PuSkeleton  width="25px" height="25px" circle></PuSkeleton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {mapGetters} from "vuex";
    import {namespaces} from "../../../store/namespaces";
    import translationApi from '../../../api/translation'
    export default {
        name: "Index",
        data() {
            return {
                categories: null,
                filteredCategories: null
            }
        },
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
            }),

        },
        methods: {
            src(locale) {
                return require('@/assets/flags/'+locale+'.png')
            },
            async init() {
                let res = await translationApi.getAll()
                this.categories = res.data.categories
                this.filteredCategories = res.data.categories
            },
            async onSaveTranslation(word) {
                try {
                    let res = await translationApi.update(word.id, word.value)
                    this.$toast.open({
                        message: this.$t('global.success'),
                        type: 'success'
                    })
                }catch (e) {
                    console.log(e)
                    this.$toast.open({
                        message: this.$t('global.error'),
                        type: 'error'
                    })
                }
            },
            onSearch(e) {
                let terms = e.target.value
                if(!terms) {
                    this.filteredCategories = this.categories
                    return
                }
                let result = {}
                for(const [categoryName, traductions] of Object.entries(this.categories)) {
                    for(const [traductionString, locales] of Object.entries(traductions)) {
                        if(traductionString.includes(terms)) {
                            result[categoryName] = result[categoryName] || {}
                            result[categoryName][traductionString] =  {...locales}
                            continue
                        }
                        for(const [locale, traductionObject] of Object.entries(locales)) {
                            if(traductionObject.value.includes(terms)) {
                                result[categoryName] = result[categoryName] || {}
                                result[categoryName][traductionString] =  {...locales}
                                break
                            }
                        }
                    }
                }
                this.filteredCategories = result
            }
        },
        mounted() {
            this.init()
        }
    }
</script>

<style scoped>
h4::first-letter {
    text-transform:capitalize;
}
.translation-wrapper {
    position: relative
}
.flag {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.traduction-area {
    box-sizing: border-box;
}
</style>
