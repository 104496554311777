import axios from "axios";
import store from "@/store";

export default {
    getAll: ()=> {
        return axios.get('/v1/admin/translations', {
            params: {
                app_id: Vue.$config.app_id
            },
            headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        });
    },
    update: (id, value)=> {
        return axios.post('/v1/admin/translations/' + id +'/update', {
            value: value,
        }, {
            headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " +  store.getters['auth/getUser'].api_token
            }
        })
    }
}